import * as React from "react";
import { Route, HashRouter as Router, Routes,Link,Outlet } from "react-router-dom";
import AppLayout from "../layout/layout";
import ProductPage from "../pages/products";
import AboutPage from "../pages/about";
import HomePage from "../pages/home";

export default function AppRouter() {
    return (
            <Routes>
                <Route path="/" element={<AppLayout />}>
                    <Route index element={<HomePage />} />
                    <Route path="produits" element={<ProductPage/>} />
                    <Route path="about" element={<AboutPage/>} />
                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
    );
}


function NoMatch() {
    return (
        <div>
            <h2>Nothing to see here!</h2>
            <p>
                <Link to="/">Go to the home page</Link>
            </p>
        </div>
    );
}
