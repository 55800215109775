import React from "react";
import {Divider, Grid, Stack, Typography} from "@mui/material";
import SimpleMap from "../components/map/map";
import PlaceIcon from '@mui/icons-material/Place';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
export default function AboutPage(){

    return(
        <>
            <Grid container sx={{mt : 8}} item justifyContent="center" xs={12}>
                <Typography color="primary" fontFamily="roboto" textAlign="center" sx={{
                    fontSize : {
                        lg : 50,
                        xs : 25
                    },
                    textTransform : "uppercase"
                }}
                >Contactez-nous </Typography>
                <Divider sx={{align : "center" ,width : "80%",backgroundColor : (t) => t.palette.primary.main}}/>
            </Grid>
            <Grid container sx={{mt : 8}} item justifyContent="center" xs={12}>
                <Stack  width="100%" alignItems="center" spacing={4}>
                    <Stack direction={"row"} alignItems="center" spacing={2}>
                        <LocalPhoneIcon/>
                        <Typography variant="h6">73 497 910 - 55 687 653 </Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems="center" spacing={2}>
                        <EmailIcon/>
                        <Typography variant="h6"> Commercial :contact@hightekdistribution.com   / SAV : sav@hightekdistribution.com</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems="center" spacing={2}>
                        <FacebookIcon />
                        <Typography
                            sx={{
                                cursor : "pointer",
                                "&:hover" : {
                                    textDecoration : "underline"
                                }
                            }}
                            onClick={() => window.location.href = "https://www.facebook.com/Hightekdistribution"}
                            variant="h6"
                        >
                            Voir la page</Typography>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <PlaceIcon sx={{fontSize : 34}}/>
                        <Typography fontFamily="roboto" variant="h6">Avenue la victoire - Téboulba, Monastir </Typography>
                    </Stack>
                    <Divider sx={{align : "center" ,width : "80%",backgroundColor : (t) => t.palette.primary.main}}/>

                    <SimpleMap/>

                </Stack>
            </Grid>
        </>
    )
}