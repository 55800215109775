import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Image from "mui-image";
import ColorfulTag from "../colorfulTag"; // requires a loader
import { Player } from 'video-react';
import {CircularProgress} from "@mui/material";


export default function ResponsiveCarousel(){


    return(
        <Carousel
            autoPlay
            infiniteLoop
            interval={4999}
            duration={4000}
            showThumbs={false}
            dynamicHeight={true}
        >

            <div>
                <Image showLoading={<CircularProgress color="primary"/>} fit="fill"  src={"/carousel/edited23.gif"}/>
                {/*<ColorfulTag/>*/}

            </div>
            <div>
                <Image showLoading={<CircularProgress color="primary"/>} fit="fill" height="80%" src={"/carousel/5.jpg"}/>
            </div>
            <div>
                <Image showLoading={<CircularProgress color="primary"/>} fit="fill" height="80%" src="/carousel/3.jpg"/>
            </div>
            <div>
                <Image showLoading={<CircularProgress color="primary"/>} fit="fill" height="80%" src="/carousel/2-01.jpg"/>
            </div>
            <div>
                <Image showLoading={<CircularProgress color="primary"/>} fit="fill" height="80%" src="/carousel/twice-vip-2021.jpg"/>
            </div>
            <div>
                <Image showLoading={<CircularProgress color="primary"/>} fit="fill" height="80%" src="/carousel/viewsonic_reward.jpg"/>
            </div>


        </Carousel>
    )
}