import React, {useState} from "react";
import {Box, CircularProgress, Divider, Grid, Paper, Skeleton, Stack, Typography} from "@mui/material";
import Image from "mui-image";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ImageViewer from "../components/imageViewer";



export default function ProductPage(){

    const [show,setShow] = useState({open : false , key : null});
    const [imageOpen, setImageOpen] = useState({open : false , img : null});

    function handleViewerOpen(img) {
        setImageOpen({open : true , img : img});
        document.getElementsByTagName('body')[0].style.overflow = "hidden"
    }
    function handleViewerClose(){
        setImageOpen({open: false , img : null});
        document.getElementsByTagName('body')[0].style.overflow = "visible"

    }
    const products = [
        {
                name : "Barrettes mémoire - DisqueSSD",
                images : [
                    "/products/Barrettesmémoire-DisqueSSD/2.png",
                    "/products/Barrettesmémoire-DisqueSSD/cvnram.jpg",
                    "/products/Barrettesmémoire-DisqueSSD/IGAME.jpg",
                    "/products/Barrettesmémoire-DisqueSSD/SSDbleu.jpg",
                    "/products/Barrettesmémoire-DisqueSSD/SSDrose.jpg",
                ]
        },
        {
            name : "Cartes graphique",
            images : [
                "/products/Cartesgraphique/colorful.jpg",
                "/products/Cartesgraphique/colorfulgpu.jpg",
                "/products/Cartesgraphique/GEFORCE.jpg",
                "/products/Cartesgraphique/RTX3050.jpg",
                "/products/Cartesgraphique/RTX3090.jpg",
                "/products/Cartesgraphique/RTX3090Ti.jpg",
            ]
        },
        {
            name : "Cartes mère",
            images : [
                "/products/Cartesmère/B550.jpg",
                "/products/Cartesmère/B660M.jpg",
                "/products/Cartesmère/cvnZ690.jpg",
                "/products/Cartesmère/igameZ690.jpg",
            ]
        },
        {
            name : "Moniteurs",
            images : [
                "/products/Moniteurs/2.jpg",
                "/products/Moniteurs/viewsonic7.webp",
            ]
        },
        {
            name : "Processeurs",
            images : [
                "/products/Processeurs/intel.jpg",
                "/products/Processeurs/ryzen.jpg",
            ]
        },
        {
            name : "PowerSupply - Boitiers",
            images : [
                "/products/PowerSupply-Boitiers/boitiers.jpg",
                "/products/PowerSupply-Boitiers/Boitiers2.jpg",
                "/products/PowerSupply-Boitiers/powersupply2.jpg",
            ]
        },

    ]
    return(
        <Grid container item xs={12} spacing={2} sx={{mb : 12}}>
            <Grid container justifyContent="center" sx={{mt : 8}} item xs={12}>
                <Typography color="primary" fontFamily="roboto" textAlign="center" sx={{
                   fontSize : {
                       lg : 50,
                       xs : 25
                   }
                }}
                >NOS PRODUITS </Typography>
                <Divider sx={{align : "center" ,width : "80%",backgroundColor : (t) => t.palette.primary.main}}/>
                {
                    imageOpen.open && <ImageViewer handleClose={handleViewerClose} img={imageOpen.img}/>
                }

            </Grid>
            {products.map((product,key) => {
                return(
                    <Grid container item xs={12}>
                        <Typography sx={{ml : 2,mt: 2,mb : 2}} variant={"h5"}>
                            {product.name}
                        </Typography>
                        <Grid container item xs={12} justifyContent={"center"} direction="row" spacing={2}>
                            {
                                product.images.map(image => {
                                    return(
                                        <Grid
                                            item
                                            xs={10}
                                            sm={5}
                                            md={4}
                                        >
                                            <Image showLoading={<Skeleton height={"380px"} width={"100%"}/>}  sx={{borderRadius : 3 , border :"red 2px solid"}} height={250} onClick={() => handleViewerOpen(image)} src={image}/>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}