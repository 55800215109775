import React, {useState} from "react";
import {Collapse, Fade, Grid, Stack, Typography} from "@mui/material";
import Logo from "./logo";
import MenuIcon from "@mui/icons-material/Menu";
import {menuItems} from "../common/menuItems";
import {useLocation, useNavigate} from "react-router-dom";


export default function MobileHeader(){
    const [menu,setMenu] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    function collapse(){
        setMenu(!menu);
    }
    return(
        <>
            <Grid container item sx={{marginTop : 4, marginLeft : 2,height : 70}}  xs={12}>
                <Stack
                    sx={{width : "100%" , height : "100%"}}
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={2}
                >
                    <Logo src="logo-horiz.png"/>
                    <MenuIcon onClick={() => collapse()} sx={{cursor : "pointer", fontSize : 38, "&:hover" : {color : "red",fontSize : 42}}}/>
                </Stack>
            </Grid>
            {menu &&
                <Fade timeout={3000} in={menu}>
                    <Grid container item justifyContent="center" alignItems="center" sx={{height : 70 , border : "1 1 1 1 solid red"}}>
                        <Stack direction="row" alignItems="center" spacing={4}>
                            {menuItems.map(item => {
                                return(
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                        sx={{
                                            cursor : location.pathname === item.path ? "" : "pointer",
                                            color : location.pathname === item.path ? "#ffffff" : "#f33333",
                                            "&:hover" : {
                                                color : "white"
                                            }
                                        }}
                                        onClick = {() => navigate(item.path)}
                                    >
                                        {item.icon}
                                        <Typography fontFamily="lobster" fontSize={20} color="inherit">{item.name}</Typography>
                                    </Stack>
                                )
                            })}
                        </Stack>
                    </Grid>
                </Fade>
                }
        </>
    )
}