import React from "react";
import {Grid} from "@mui/material";
import Image from "mui-image";
import CancelSharpIcon from '@mui/icons-material/CancelSharp';


export default function ImageViewer({img,handleClose}){

    return(
        <Grid container sx={{
            zIndex : 1010,
            position : "fixed",
            top : 0,
            left : 0,
            backgroundColor : "rgba(105, 104, 104, 0.7)",
            width : "1000vh",
            height : "100vh",
            overflow : "hidden"
        }}
              xs={12}
              justifyContent="center"
              alignContent="center"
        >
            <CancelSharpIcon
                onClick={() => handleClose()}
                sx={{
                    cursor : "pointer",
                    fontSize : 50,
                    position : "absolute",
                    zIndex : 1020,
                    top : "4%",
                    right : "4%",
                    "&:hover" : {
                        color : (theme) => theme.palette.primary.main,
                    }
                }}
            />
            <Image  fit="contain" src={img}/>
        </Grid>
    )
}