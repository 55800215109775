import './App.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import mainTheme from "./themes/main";
import AppRouter from "./routers/router";

function App() {
  return (
      <ThemeProvider theme={mainTheme}>
        <CssBaseline/>
          <AppRouter/>
      </ThemeProvider>
  );
}

export default App;
