import React from "react";
import HomeCarousel from "../components/home/carousel";
import {Divider, Grid, Skeleton, Stack, Typography} from "@mui/material";
import Image from "mui-image";
import ResponsiveCarousel from "../components/home/responsiveCarousel";
import {Carousel} from "react-responsive-carousel";



export default function HomePage(){

    return(
        <Grid container item xs={12}>
            <Grid container item sx={{mt : 2}} justifyContent="center" xs={12}>
                <ResponsiveCarousel/>
            </Grid>
            <Grid container item sx={{pt:8}}  xs={12}>
                <Stack sx={{width : "100%",mb: 8}} alignItems="center" spacing={2}>
                    <Typography textAlign="center" color="primary" fontWeight={900} sx={{fontSize : {xs : 20,md : 34}}}>En partenariat avec des grandes marques</Typography>
                    <Divider sx={{backgroundColor : "#b3b3b3",width : "70%"}}/>
                    {/*<Stack direction={{ xs: 'column', lg: 'row' }} spacing={2}>*/}
                    {/*    */}

                    {/*</Stack>*/}
                    <Grid container item xs={12} spacing={2}  justifyContent="center">
                        <Grid item xs={6} sm={3} md={3} lg={2}>
                            <Image showLoading={<Skeleton width={100} height={100}/> } width="150px" fit={"scale-down"} src={"/partners/intel.png"}/>
                        </Grid>
                            <Grid item xs={6} sm={3} md={3} lg={2}>
                                <Image showLoading={<Skeleton width={100} height={100}/> } width="150px" fit={"scale-down"} src={"/partners/ryzen2.png"}/>
                            </Grid>
                            <Grid showLoading={<Skeleton width={100} height={100}/> } item xs={6} sm={3} md={3} lg={2}>
                                <Image width="150px" fit="contain" src={"/partners/colorful-01.jpg"}/>
                            </Grid>
                        <Grid showLoading={<Skeleton width={100} height={100}/> } item xs={6} sm={3} md={3} lg={2}>
                            <Image width="150px" fit="contain" src={"/partners/igame.png"}/>

                        </Grid>
                        <Grid showLoading={<Skeleton width={100} height={100}/> } item xs={6} sm={3} md={3} lg={2}>
                            <Image width="150px" fit="contain" src={"/partners/segotep2.png"}/>

                        </Grid>
                        <Grid showLoading={<Skeleton width={100} height={100}/> } item xs={6} sm={3} md={3} lg={2}>
                            <Image width="150px" fit="contain" src={"/partners/steelseries-logo.png"}/>

                        </Grid>
                        <Grid showLoading={<Skeleton width={100} height={100}/> } item xs={6} sm={3} md={3} lg={2}>
                            <Image width="150px" fit="contain" src={"/partners/Corsair-Gaming.png"}/>

                        </Grid>
                        <Grid showLoading={<Skeleton width={100} height={100}/> } item xs={6} sm={3} md={3} lg={2}>
                            <Image width="150px" fit="contain" src={"/partners/viewsonic3.png"}/>

                        </Grid>
                        <Grid showLoading={<Skeleton width={100} height={100}/> } item xs={6} sm={3} md={3} lg={2}>
                            <Image width="150px" fit="contain" src={"/partners/wd.png"}/>
                        </Grid>
                        <Grid showLoading={<Skeleton width={100} height={100}/> } item xs={6} sm={3} md={3} lg={2}>
                            <Image width="150px" fit="contain" src={"/partners/samsung.png"}/>
                        </Grid>
                        <Grid showLoading={<Skeleton width={100} height={100}/> } item xs={6} sm={3} md={3} lg={2}>
                            <Image width="150px" fit="contain" src={"/partners/logitech-logo.png"}/>
                        </Grid>
                        <Grid showLoading={<Skeleton width={100} height={100}/> } item xs={6} sm={3} md={3} lg={2}>
                            <Image width="150px" fit="contain" src={"/partners/nzxt.png"}/>
                        </Grid>
                        <Grid showLoading={<Skeleton width={100} height={100}/> } item xs={6} sm={3} md={3} lg={2}>
                            <Image width="150px" fit="contain" src={"/partners/thermaltake.png"}/>
                        </Grid>
                        <Grid showLoading={<Skeleton width={100} height={100}/> } item xs={6} sm={3} md={3} lg={2}>
                            <Image width="150px" fit="contain" src={"/partners/razer.png"}/>
                        </Grid>
                    </Grid>
                </Stack>
                <Stack sx={{width : "100%",mb: 8}} alignItems="center" spacing={2}>
                    <Divider sx={{backgroundColor : "#b3b3b3",width : "70%"}}/>
                    <Typography width="80%" fontFamily="roboto"  variant="h6">
                        HIGHTEK DISTRIBUTION  grossiste informatique,high-tech  pour les professionnels et les revendeurs en partenariat avec  les grandes marques.nous proposons à la vente en gros une large gamme de produits high- tech  avec des garanties, des avantages uniques et des marques exclusives
                    </Typography>
                    <Typography xs={{}} width="80%"  fontFamily="roboto"  variant="body1">
                        Nos processus logistiques nous permettent des approvisionnements très réguliers de la part des marques partenaires, ainsi qu’une expédition ultra rapide vers vos points de vente.
                    </Typography>
                </Stack>
            </Grid>

        </Grid>
    )
}