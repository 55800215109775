import React from "react";
import {Divider, Grid, Hidden, makeStyles, Typography} from "@mui/material";
import Image from "mui-image";
import HomeIcon from '@mui/icons-material/Home';
import CategoryIcon from '@mui/icons-material/Category';
import EmailIcon from '@mui/icons-material/Email';
import SideBar from "./components/sideBar";
import { Outlet } from "react-router-dom";

export default function AppLayout(){


    return(
        <Grid container item xs={12}>
            <Grid
                container
                xs={12}
                spacing={2}
                alignItems="flex-start"
            >
                <Grid container  item sm={12} md={3}>
                    <SideBar/>
                </Grid>
                <Grid container  item sm={12} md={9}>
                    <Outlet/>
                </Grid>
            </Grid>
            <Grid container sx={{mb : 6 , height : 70, borderTop : '1px white solid'}} justifyContent="center" alignContent="center" item xs={12}>
                <Typography variant="h6">
                    Tous droits réservés © Hightek distribution
                </Typography>
            </Grid>
        </Grid>
    )
}