import {createTheme} from "@mui/material";
const mainTheme = createTheme({
    palette : {
        primary : {
            main : "#f33333"
        },
        background : {
            default : "rgba(0, 0, 0, 0.95)"
        },
        text : {
            primary : "#ffffff"
        }
    },
    typography : {
        fontFamily : "lobster"
    }
});

export default mainTheme;