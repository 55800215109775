import React from "react";
import {Divider, Grid, Hidden, Paper, Stack, Typography} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Image from "mui-image";
import HomeIcon from "@mui/icons-material/Home";
import CategoryIcon from "@mui/icons-material/Category";
import EmailIcon from "@mui/icons-material/Email";
import Logo from "../../components/logo";
import MenuIcon from '@mui/icons-material/Menu';
import MobileHeader from "../../components/mobileHeader";
import {menuItems} from "../../common/menuItems";
import {useLocation, useNavigate} from "react-router-dom";


export default function SideBar(){
    const navigate = useNavigate();
    const location = useLocation();
    console.log("location",location)
    return(
        <>
            <Hidden mdDown>
                <Grid
                    container
                    item
                    xs={12}
                    direction="column"
                    justifyContent="flex-start"
                    sx={{
                        padding : 8 ,
                        height : "100vh",
                        borderRight : "4px solid #3D0000",
                    }}
                >

                            <Grid
                                container
                                item
                                justifyContent="center"
                                sx={{mt:5,mb : 5}}
                            >
                                <Logo/>
                            </Grid>
                            <Grid
                                container
                                item
                                direction="column"
                                justifyContent="center"
                                rowSpacing={4}
                            >
                                {
                                    menuItems.map(item => {
                                        return(
                                            <>
                                                <Grid
                                                    sx={{
                                                        cursor : location.pathname === item.path ? "" : "pointer",
                                                        color : location.pathname === item.path ? "#ffffff" : "#f33333",
                                                        "&:hover" : {
                                                            color : "white"
                                                        }
                                                    }}
                                                    container
                                                    item
                                                    alignContent="center"
                                                    justifyContent={"center"}
                                                    columnSpacing={1}
                                                    onClick={() => {
                                                        navigate(item.path)
                                                    }}
                                                >

                                                    <Grid container item justifyContent="center" xs={12}>
                                                        <Grid container  item alignItems="center" justifyContent="center" xs={2}>
                                                            {item.icon}
                                                        </Grid>
                                                        <Grid container item xs={5}>
                                                            <Typography fontFamily="lobster" fontSize={25} color="inherit">{item.name}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Divider sx={{backgroundColor : "#b3b3b3"}}/>
                                            </>

                                        )
                                    })
                                }
                                {/*<Typography >Social Media</Typography>*/}
                            </Grid>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                    <MobileHeader/>
            </Hidden>
        </>
    )
}