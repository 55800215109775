import HomeIcon from "@mui/icons-material/Home";
import CategoryIcon from "@mui/icons-material/Category";
import EmailIcon from "@mui/icons-material/Email";
import React from "react";


export const menuItems = [
    {
        name : "Accueil",
        icon : <HomeIcon/>,
        path : "/"
    },
    {
        name : "Produits",
        icon : <CategoryIcon/>,
        path : "/produits"
    },
    {
        name : "Contact",
        icon : <EmailIcon/>,
        path: "/about"
    }
];